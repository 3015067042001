export const SocialIcon=(props)=>{
    return(
     <>
     <div class="icon-bar" id="Icon">
     <ul class="ct-socials">
  <li>
    <a href="https://twitter.com/TelexaL" target="_blank"><i class="fa fa-twitter"></i></a>
  </li>
  <li>
    <a href="https://www.facebook.com/telexaindia/" target="_blank"><i class="fa fa-facebook"></i></a>
  </li>
  <li>
    <a href="https://www.youtube.com/channel/UCJYBIQnEU-u6itz2VsRNQ_g" target="_blank"><i class="fa fa-google fa"></i></a>
  </li>
  <li>
    <a href="https://www.linkedin.com/in/telexa/" target="_blank"><i class="fa fa-linkedin"></i></a>
  </li>
  <li>
    <a href="https://www.instagram.com/telexaindia/" target="_blank"><i class="fa fa-instagram fa"></i></a>
  </li>
</ul>
</div>
     </>
    );
}