import emailjs from 'emailjs-com';
import { useState } from 'react';
import axios from 'axios';
const initialState = {
  name: '',
  email: '',
  jobTitle:'',
  resume:[],
  message: '',

}
export const Career = (props) => {
  // const[file, setFile] = useState();
  // const send=event=>{
  // const data = new FormData();
  // data.append("file",file);
  // axios.post("http://localhost:3000/upload",data).then(
  //   res=>console.log(res)
  // ).catch(
  //   err=>console.log(err)
  // );
  // };
  //////////////
  const [{ name, email,jobTitle,resume, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email,jobTitle,resume, message)
    emailjs
      .sendForm(
        'service_pjwk5t1', 'template_iclq51q', e.target, 'DkLrFeBCXY9Nz8WdI'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      );
      e.target.reset();
  }
  return (
    <div class="container register" id='career'>
      <div class="row">
        <div class="col-md-3 register-left">
          {/* <img src="https://image.ibb.co/n7oTvU/logo_white.png" alt=""/> */}
          {/* <h3>Welcome</h3> */}
          {/* <p>You are 30 seconds away from earning your own money!</p> */}
        </div>
        <div class="col-md-9 register-right">
          <div id="myTab">
            <div className="container">
              <div className="col-md-8">
                <div className="row">
                  <div className="section-title">
                    <h2 class="register-heading">Get In Touch</h2>
                    <p>
                      Please fill out the form below to send us an email and we
                      will get back to you as soon as possible.
                    </p>
                  </div>
                  <form 
                  action="/sendmail"
                   method="Post" 
                  onSubmit={handleSubmit}
                   enctype="multipart/form-data">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            placeholder="Name"
                            required
                            onChange={handleChange}
                          />
                          <p className="help-block text-danger"></p>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Email"
                            onChange={handleChange}
                            required
                          />
                          <p className="help-block text-danger"></p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                   <select class="form-control"  id="jobTitle" name="jobTitle"  aria-label=".form-select-sm example" placeholder="Select Job Tittle" 
                   onChange={handleChange} 
                   >
                            <option selected>Select Job Title</option>
                            <option value="Telly Sales">Telly Sales</option>
                            <option value="Soldering Techinican">Soldering Techinican </option>
                            <option value="Testing And Support">
                              Testing And Support
                            </option>
                            <option value="Production Manager">
                                Production Manager
                            </option>
                            <option value="Embedeed Developer">
                               Embedded Developer
                            </option>
                            <option value="Software Developer">Software Developer</option>
                            <option value="other">Other</option>

                          </select>
                          <p className="help-block text-danger"></p>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                        <div  class="input-group custom-file-button">
                        {/* <label class="input-group-text" for="inputGroupFile">Your Custom Text</label> */}

                   {/* <label for="formFile" class="form-label">Default file input example</label> */}
                  <input  type="file"class="form-control" id="inputGroupFile" accept=".pdf"
                  //  onChange={event=>{
                  //   const file = event.target.files[0];
                  //   setFile(file)
                  // }}
                  /> 
                  {/* <button onClick={send}>upload</button> */}
</div>
                        {/* <input type="file" class="form-control-file" id="resume" name="resume" onChange={handleChange} required/>
                          <p className="help-block text-danger"></p> */}
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <textarea
                        name="message"
                        id="message"
                        className="form-control"
                        rows="4"
                        // onChange={handleChange}
                        placeholder="Message"
                      ></textarea>
                      <p className="help-block text-danger"></p>
                    </div>
                    <div id="success"></div>
                    <button type="submit" class="btnRegister">
                      Send Message
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div id='footer'>
          <div className='container text-center'>
            <p>
              &copy; 2022 Telexa Technology{' '}
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};


// import emailjs from 'emailjs-com';
// import { useState } from 'react';
// export const Career = (props) => {
//   return (
//     <div class="container register" id='career'>
//       <div class="row">
//         <div class="col-md-3 register-left">
         
//         </div>
//         <div class="col-md-9 register-right">
//           <div id="myTab">
//             <div className="container">
//               <div className="col-md-8">
//                 <div className="row">
//                   <div className="section-title">
//                     <h2 class="register-heading">Get In Touch</h2>
//                     <p>
//                       Please fill out the form below to send us an email and we
//                       will get back to you as soon as possible.
//                     </p>
//                   </div>
//                   <form action="/sendmail" method="Post"  enctype="multipart/form-data">
//                     <div className="row">
//                       <div className="col-md-6">
//                         <div className="form-group">
//                           <input
//                             type="text"
//                             id=""
//                             name="name"
//                             className="form-control"
//                             placeholder="Name"
//                             required
                           
//                           />
//                           <p className="help-block text-danger"></p>
//                         </div>
//                       </div>

//                       <div className="col-md-6">
//                         <div className="form-group">
//                           <input
//                             type="email"
//                             id=""
//                             name="email"
//                             className="form-control"
//                             placeholder="Email"
//                             // onChange={handleChange}
//                             required
//                           />
//                           <p className="help-block text-danger"></p>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="row">
//                       <div className="col-md-6">
//                         <div className="form-group">
//                    <select class="form-control"  id="" name="jobTitle"  aria-label=".form-select-sm example" placeholder="Select Job Tittle" >
//                             <option selected>Select Job Title</option>
//                             <option value="Telly Sales">Telly Sales</option>
//                             <option value="Soldering Techinican">Soldering Techinican </option>
//                             <option value="Testing And Support">
//                               Testing And Support
//                             </option>
//                             <option value="Production Manager">
//                                 Production Manager
//                             </option>
//                             <option value="Embedeed Developer">
//                                Embedded Developer
//                             </option>
//                             <option value="Software Developer">Software Developer</option>
//                             <option value="other">Other</option>

//                           </select>
//                           <p className="help-block text-danger"></p>
//                         </div>
//                       </div>

//                       <div className="col-md-6">
//                         <div className="form-group">
//                         <div class="mb-3">
//                    {/* <label for="formFile" class="form-label">Default file input example</label> */}
//                   <input class="form-control" type="file" id="" name='resume'/>
// </div>
//                         {/* <input type="file" class="form-control-file" id="resume" name="resume" onChange={handleChange} required/>
//                           <p className="help-block text-danger"></p> */}
//                         </div>
//                       </div>
//                     </div>

//                     <div className="form-group">
//                       <textarea
//                         name="message"
//                         id=""
//                         className="form-control"
//                         rows="4"
//                         placeholder="Message"
//                       ></textarea>
//                       <p className="help-block text-danger"></p>
//                     </div>
//                     <div id="success"></div>
//                     <button type="submit" class="btnRegister">
//                       Send Message
//                     </button>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* <div id='footer'>
//           <div className='container text-center'>
//             <p>
//               &copy; 2022 Telexa Technology{' '}
//             </p>
//           </div>
//         </div> */}
//       </div>
//     </div>
//   );
// };

