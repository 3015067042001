import { Navigation } from "./navigation";
import { SocialIcon } from "./scoailIcon";
const Header = (props) => {
  return (

    <header id="header">
          <SocialIcon/>
     <div class="container">
           <div className="row">
        <div id="carousel-demo" 
             class="carousel slide" 
             data-ride="carousel">
            <div class="carousel-inner">
                <div class="item">
                    <img src=
"../img/bgimg.jpg"/>
                </div>
                <div class="item">
                    <img src=
"../img/bgimg.jpg"/>
                </div>
                <div class="item active">
                    <img src=
"../img/bgimg.jpg"/>
                </div>
            </div>
            
            <a class="left carousel-control"
               href="#carousel-demo"
               data-slide="prev">
               
            </a>
            <a class="right carousel-control" 
               href="#carousel-demo" 
               data-slide="next">
               
            </a>
        </div>
        </div>
     {/* <diV className="col">
            <iframe
              // style={{ borderRadius: "2%" ,marginRight:"40%"}}
              src="https://www.youtube.com/embed/fHjH-fJa934"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>   

       </diV> */}
     <div class="row">
      <div class="col-8 col-sm-6">
      <iframe
              // style={{ borderRadius: "2%" ,marginRight:"40%"}}
              src="https://www.youtube.com/embed/fHjH-fJa934"
              title="YouTube video player"
              frameBorder="0"
           
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>   
      </div>
      <div class="col-4 col-sm-6">
       {/* <h2 style={{color:"black"}}>
                 {props.data ? props.data.best : 'Loading'} 
             <span></span>
             </h2>
             <p style={{fontSize:"18"}}>{props.data ? props.data.Best : 'Loading'}</p> */}
          <div class="card">
          <div class="card-body">
          {/* <h2 class="card-title"> {props.data ? props.data.best : 'Loading'} </h2> */}

         <p class="card-text">{props.data ? props.data.Best : 'Loading'}</p>
    
  </div>
</div>
              {' '}
      </div>
    </div>

</div>

    
  



       
       
    
   



</header>
    // <header id='header'>
    //   <div className='intro'>
    //     <div className='overlay'>
    //       <div className='container'>
    //         <div className='row'>
    //           <div className='col-12 col-sm-6 col-md-8-offset-2 intro-text'>
    //             <h2 style={{color:"white"}}>
    //               {props.data ? props.data.title : 'Loading'} 
    //               <span></span>
    //             </h2>
    //             <p style={{fontSize:"18"}}>{props.data ? props.data.paragraph : 'Loading'}</p>
    //             <a
                  
    //               href='/features'className='btn btn-custom btn-lg page-scroll'
    //             >
    //               Learn More
    //             </a>{' '}
    //           </div>
              
    //         </div>
    //       </div>
 
    //     </div>
    //   </div>
    // </header>
  )
}
export default Header;