export const Download=(props)=>{
return(
<>
<div id="download">
<div class="container" >
{/* <div class="row">
  <div class="col-sm-6">
 
  <img class="card-img-top" src="../img/motorcontroller/one.png" />
  
</div>
<div class="col-sm-6">
 
<div class="card">
  <h5 class="card-header">Featured</h5>
  <div class="card-body">
    <h5 class="card-title">Special title treatment</h5>
    <p class="card-text">With supporting text below as a natural lead-in to additional content.
    With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.
    With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.</p>
    <a href="#" class="btn btn-primary">Go somewhere</a>
  </div>
</div>
  </div>
</div> */}
  
  {/* <div class="row">
  
<div class="col-sm-6">
 
<div class="card">
  <h5 class="card-header">Featured</h5>
  <div class="card-body">
    <h5 class="card-title">Special title treatment</h5>
    <p class="card-text">With supporting text below as a natural lead-in to additional content.
    With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.
    With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.</p>
    <a href="#" class="btn btn-primary">Go somewhere</a>
  </div>
</div>

  </div>
  <div class="col-sm-6">
  <img class="card-img-top" src="../img/motorcontroller/one.png" /> 
</div>
 
</div> */}
<div class="row">
  <div class="col-sm-6">
  <img class="card-img-top" src="../img/TelexaAuto/one.jpeg" style={{width:150, marginTop:25}} /> 
  <img class="card-img-top" src="../img/TelexaAuto/two.jpeg" style={{width:150,marginTop:25}} /> 
  <img class="card-img-top" src="../img/TelexaAuto/three.jpeg" style={{width:150,marginTop:25}} /> 
  </div>
  <div class="col-sm-6">
    <h3 style={{marginTop:85}}>Telexa Auto</h3>
    <p>Telexa Auto is designed to support Telexa Mobile Auto. This app gives reach feature to its users and easy to navigation. Mobile Auto Switch is a GSM technology-based motor control that communicates with the farmer’s mobile phone and keeps updated with the status of the motor that is no need to go to operate the motor.</p>
    <a href="../img/telexa mobile_auto.apk" class="btn btn-primary" target="blank" >Download App</a>
  </div>
</div>
<div class="row">
<div class="col-sm-6">
    <h3 style={{marginTop:85}}>Telexa Vision</h3>
    <ul>
      <li>Real time plant monitoring.</li>
      <li> Display information filter plant wise.</li>
      <li> Display information filter date wise.</li>
      <li> It will also display total vehicle of a plant.</li>
    </ul>
    <a href="https://play.google.com/store/apps/details?id=com.telexa.telexavision" class="btn btn-primary" target="blank" >Download App</a>
  </div>
  <div class="col-sm-6">
  <img class="card-img-top" src="../img/TelexaVision/telexavision1.webp" style={{width:150, marginTop:25}} /> 
  <img class="card-img-top" src="../img/TelexaVision/telexavision2.webp" style={{width:150,marginTop:25}} /> 
  <img class="card-img-top" src="../img/TelexaVision/tetexavision3.webp" style={{width:150,marginTop:25}} /> 
  </div>
</div>
</div>
<div class="container catlogs">
  <h3>Catalogs</h3>
<div id='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4 col-sm-6 team'>
                  <div className='thumbnail'>
                    {' '}   
                    {/* <i class="fa fa-file-pdf-o" aria-hidden="true"> */}
                      <img src="../img/pdf.jpg"/>
                         <h5> {d.name}</h5>
                 
                   
                    {/* <img src={d.pdf} alt='...' className='team-img' /> */}
                    <div className='caption'>
                    <a href={d.pdf} download={d.name}>
                    <button class="btn"><i class="fa fa-download"></i> Download</button>
                    </a>
                      {/* <p>{d.job}</p> */}
                    </div>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
</div>
</div>
</>
);
}