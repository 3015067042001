
export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      
      <div className='container'>
        <div className='navbar-header'>
         <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <img
        src="img/logo.jpg"
        // width="150%"
        // height="130%"
        className="d-inline-block align-top"
        alt=""
        style={{marginLeft:"0%"}}
        />{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
          <li>
              <a href='/' className='page-scroll'>
                Home
              </a>
            </li>
          
            <li>
              <a href='/about' className='page-scroll'>
                About
              </a>
            </li>
         
            <li>
              <a href='/gallery' className='page-scroll'>
                 PRODUCTS
              </a>
             
            </li>
          
            <li>
              <a href='/download' className='page-scroll'>
                Download's
              </a>
            </li>
            <li>
              <a href="/support" className='page-scroll'>
              Support
              </a>
             
            </li>
            <li>
              <a href="/image" className='page-scroll'>
               Gallery
              </a>
             
            </li>
            <li>
              <a href='/contact' className='page-scroll'>
                Contact
              </a>
            </li>
            <li>
              <a href="/career" className='page-scroll'>
                  Career
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  
  );
}
