
import React, { useState, useEffect } from "react";
import { get, find, map } from "lodash";
import { Button, Modal, Card, Container, Col, Row } from "react-bootstrap";
import "../App.css";
export const Gallery = (props) => {
  
  const products = get(props, 'data', [])
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [features, setFeatures] = useState([[]]);
  const [img, setImg] = useState([[]]);
  const handleShow = (event) => {
    // console.log(event.target.value)
  var productInfo =find(products, (product) => product.id == event.target.value);
    // console.log(productInfo)
  setSelectedProduct(productInfo);
    setShow(true);
    setFeatures(get(productInfo, "feature"));
    setImg(get(productInfo, "img"));
  };
  useEffect(() => {
    setFeatures(get(selectedProduct, "feature", {}));
    setImg(get(selectedProduct, "img"));
  }, [ handleShow]);
  return (
    <>

{/* <div class="row" id="portfolio">
  <div class="col-sm-4">
  {props.data &&
                props.data.map((d, i) => {
                  const imgList = d.img;
                  let img = [];
                  for (const value in d.img) {
                    img.push(imgList[value]);
                  }
                  return(
    <div class="card"
    key={`${d.id}-${i}`}
   
    style={{ marginLeft: "12%" }}>
      <div class="col-sm-6 col-md-3 col-lg-4">
      <img src={img[0]} className="img-responsive" alt={""} />
        <h5 class="card-title">{d.name}</h5>
        <a href="#" class="btn btn-primary"   onClick={handleShow}
                          value={d.id}>Go somewhere</a>
      </div>
    </div>
     );
    })}
  </div>
</div> */}
      
      {/* <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
          
          </div>
          <div className="row">
            <div className="portfolio-items">
              {props.data &&
                props.data.map((d, i) => {
                  const imgList = d.img;
                  let img = [];
                  for (const value in d.img) {
                    img.push(imgList[value]);
                  }

                  return (
                    <Card>
                      <div
                       
                        className="col-sm-6 col-md-3 col-lg-4"
                      
                      >
                        <img src={img[0]} className="img-responsive" alt={""}/>
                        <h4>{d.name}</h4>
                        <br></br>
                        <Button
                          variant="primary"
                          onClick={handleShow}
                          value={d.id}
                        >
                          More Info
                        </Button>
                        <br></br>
                      </div>
                    </Card>
                  );
                })}
            </div>
          </div>
        </div>
      </div> */}
<div class="container d-flex justify-content-center mt-50 mb-50" id="product">
            
            <div class="row">
            {props.data &&
                props.data.map((d, i) => {
                  const imgList = d.img;
                  let img = [];
                  for (const value in d.img) {
                    img.push(imgList[value]);
                  }
        return(
               <div class="col-md-4 mt-2"
             >
                
                    
                    <div class="card"  key={`${d.id}-${i}`}>
                                        <div class="card-body">
                                            <div class="card-img-actions">
                                                
                                            <img src={img[0]} className="img-responsive" alt={""} />                                                  
                                               
                                            </div>
                                        </div>
    
                                        <div class="card-body bg-light text-center">
                                            <div class="mb-2">
                                                <h6 class="font-weight-semibold mb-2">
                                                    <a href="#" class="text-default mb-2" data-abc="true">{d.name}</a>
                                                </h6>
    
                                                   {/* <a href="#" class="text-muted" data-abc="true">Laptops & Notebooks</a> */}
                                            </div>
    
                                            {/* <h3 class="mb-0 font-weight-semibold">
                                            <i class="fa fa-inr" aria-hidden="true">{d.price}</i>
                                            </h3> */}
    
                                            {/* <div>
                                               <i class="fa fa-star star"></i>
                                               <i class="fa fa-star star"></i>
                                               <i class="fa fa-star star"></i>
                                               <i class="fa fa-star star"></i>
                                            </div> */}
    
                                            {/* <div class="text-muted mb-3">34 reviews</div> */}
                                      <button type="button" class="btn bg-cart"   variant="primary" onClick={handleShow} value={d.id}>Learn More</button>
    
                                            
                                        </div>
                                               </div>
    
    
                                
                                 
               </div> 
    
    
    
    
               );
              })}
                    
    
            </div>
        </div>

      <Modal
        show={show}
        onHide={handleClose}
        fade={false}
        size="lg"
        id="Model"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        style={{ opacity: 1, position: "absolute", padding: "20%" }}
        product={selectedProduct}
      >
      
        <Modal.Header>
          <Modal.Title>{get(selectedProduct, "name")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            <Row>
              <Col xs={6} md={5}>
                {img &&
                  img.map((record, index) => (
                    <img
                      src={record}
                      alt=" "
                      key={index}
                      width="200px"
                      height="200px"
                    />
                  ))}
              </Col>
              <Col xs={6} md={4}>
                <h4 style={{ marginTop: "5%", marginLeft: "5%" }}>
                  Key features :-
                </h4>
                <ul>
                  {features &&
                    map(features,(record, index) => (
                      <li key={index}>{record}</li>
                    ))}
                </ul>
              </Col>
            </Row>

            <Row>
              <Col xs={6} md={5} style={{ marginTop: "5%" }}>
                <iframe
                  width="410"
                  height="250"
                  style={{ borderRadius: "10px" }}
                  src="https://www.youtube.com/embed/W6F6z7uRhr0"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Col>
              <Col xs={6} md={4} style={{ marginTop: "5%" }}>
                {get(selectedProduct, "description")}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
