import React, {useState, useRef} from 'react'
import axios from 'axios';
export const Support=()=>{
    const [name, setname] = useState(' ');
    const [complainType, setcomplainType] = useState(' ');
    const[deviceType, setdeviceType] = useState(' ');
    const[email, setemail] = useState(' ');
    const[mobileNo,setmobileNo]= useState(' ');
    const[address,setaddress]=useState(' ');
    const[warrentyType, setwarrentyType]=useState(' ');
    const[complain, setcomplain] = useState(' ');
     const handleSumbit =(e)=>{
        e.preventDefault();
        const data={
            complainType: complainType,
            deviceType : deviceType,
            name : name,
            email : email,
            address : address,
            mobileNo : mobileNo,
            warrentyType:warrentyType,
            complain:complain
        }
        axios.post('https://sheet.best/api/sheets/0029e15a-456e-432a-ba74-43bd0581c721',data).then((response)=>{
            console.log(response);
            setcomplainType(' ');
            setdeviceType(' ');
            setname(' ');
            setemail(' ');
            setaddress('');
            setmobileNo(' ');
            setwarrentyType(' ');
            setcomplain(' ');
        })
        // console.log(complainType,deviceType,name,email,address,warrentyType,complain);
     }
    return(
        <div class='container' id="support">
        <div class="container contact-form" >
            <div class="contact-image">
                <img src="https://image.ibb.co/kUagtU/rocket_contact.png" alt="rocket_contact"/>
            </div>
            <form method="post" name="supportData" onSubmit={handleSumbit}>
                <h3>Drop Us a Message</h3>
                
                <div class="row">
                <div class="col-md-6">
                <div class="form-group">
                <label for="complainType">Select Status</label>
                 <select class="form-control form-control-lg" name="complainType" onChange={(e)=>setcomplainType(e.target.value)} value={complainType}>
                  <option selected >Select Status</option>
                  <option>Complain</option>
                  <option>Installation</option>
                 </select>
                 </div>
                     </div>
                     <div>
                     <div class="col-md-6">
                     <div class="form-group">
                     <label for="deviceType">Select Product</label>
                     <select class="form-control form-control-lg" name="deviceType" onChange={(e)=> setdeviceType(e.target.value)} value={deviceType}>
                  <option selected>Select Product</option>
                  <option>Mobile Auto Switch</option>
                  <option>Mobile Auto Switch-4G</option>
                  <option>Mobile Auto Switch With Battery</option>
                  <option>Water Level Controller</option>
                  <option>water level Automation</option>
                  <option>Water level Automation-4G</option>
                 </select>
                 </div>
                     </div>
                     </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="from-group">
                    <label for="name">Name</label>

                    <input type="text" name="name" class="form-control" placeholder='Message'  onChange={(e)=>setname(e.target.value)} value={name} />
                    </div>         
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                    <label for="email">Email address</label>
                    <input type="text" name="email" class="form-control" placeholder="Your Email *" onChange={(e)=>setemail(e.target.value)}  value={email}/>     
                  </div>
                  </div>
                </div>
               <div class="row">
                     <div class="col-md-6">
                        <div class="form-group">
                        <label for="mobileNo">Mobile Number</label>
                            <input type="text" name="mobileNo" class="form-control" placeholder="Your Phone Number *" onChange={(e)=>setmobileNo(e.target.value)} value={mobileNo} />
                        </div>
                        <div class="form-group">
                        <label for="address">Your Address</label>
                            <input type="text" name="address" class="form-control" placeholder="Your Address*" onChange={(e)=>setaddress(e.target.value)} value={address} />
                        </div>
                        <div class="form-group">
                        <label for="warrentyType">Select Warrenty Type</label>
                <select class="form-control form-control-lg" name="warrentyType" onChange={(e)=>setwarrentyType(e.target.value)} value={warrentyType}>
                  <option selected>Select Product Warrenty Status</option>
                   <option>IN Warrenty</option>
                   <option>With Out Warrenty</option>
                   </select>          
               </div>    
                    </div>
                    <div class="col-md-6">
                        <div class="form-group" >
                        <label for="complain">Complain Description</label>
                            <textarea name="complain" type="text" class="form-control" placeholder="Your Complain *" style={{width: "100%", height: "150px"}}  onChange={(e)=>setcomplain(e.target.value)} value={complain} ></textarea>
                        </div>
                        </div>
                        <div class="col-md-6">
                        <div class="form-group" >
                            <input type="submit" name="btnSubmit" class="btnContact" value="Send Message" />
                        </div>
                        </div>
                    
                </div>
                </form>
                </div>
                </div>


    );

}