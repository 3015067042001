export const Footer=(props)=>{
 return (

<footer class="text-center text-lg-start bg-light text-muted" id="footer">
  <section class="">
    <div class="container text-center text-md-start mt-5">
   
      <div class="row mt-3">
       
        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
            
          <h4 class="text-uppercase fw-bold mb-4">
            {/* <i class="fas fa-gem me-3"></i> */}
            <img src="../img/flogo.jpeg" width={40} style={{marginRight:'5'}}/>
            Telexa Technology Pvt. Ltd.
          </h4>
         
          <p>
          Telexa Technology Pvt Ltd Is A Research & Development Company Which Aims To Solve The Problems Of Agriculture, Home, Offices, And Industries & Government.
          </p>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-5 mx-auto mb-6">
      
          <h5 class="text-uppercase fw-bold mb-4">
            Useful links
          </h5>
           <a class="btn btn-outline-light btn-floating m-1" href="https://www.facebook.com/telexaindia/" role="button" target="blank">
        <i class="fa fa-facebook fa-2x" aria-hidden="true"></i>
            </a>
   
       
       <a class="btn btn-outline-light btn-floating m-1" href="https://twitter.com/TelexaL" target="blank" role="button">
        <i class="fa fa-twitter fa-2x" aria-hidden="true"></i>
          </a>
   
         
         <a class="btn btn-outline-light btn-floating m-1" href="https://www.youtube.com/channel/UCJYBIQnEU-u6itz2VsRNQ_g" target="blank" role="button">
        <i class="fa fa-youtube fa-2x" aria-hidden="true"></i>
           </a>
           <a class="btn btn-outline-light btn-floating m-1" href="https://www.instagram.com/telexaindia/" target="blank" role="button">
        <i class="fa fa-instagram fa-2x" aria-hidden="true"></i>
           </a>
   
       
           <a class="btn btn-outline-light btn-floating m-1" href="https://api.whatsapp.com/send/?phone=%2B918962307685&amp;text&amp;app_absent=0" target="blank" role="button">
           <i class="fa fa-whatsapp fa-2x" aria-hidden="true"></i>
           </a>
   
     
      <a class="btn btn-outline-light btn-floating m-1" href="https://www.linkedin.com/in/telexa/" target="blank" role="button">
        <i class="fa fa-linkedin-square fa-2x " aria-hidden="true"></i>
         </a>
        </div>
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
        
          <h5 class="text-uppercase fw-bold mb-4">Contact</h5>
          <p> <a href="http://maps.google.com/maps?q=34/2-C+New Palasia,+Indore,+ M.P.-452001" target="blank"> <i class="fa fa-home" aria-hidden="true"></i> 34/2-C New Palasia, Indore, M.P. </a></p>
          <p><a  href = "mailto:telexaindia@gmail.com"><i class="fa fa-envelope" aria-hidden="true"></i> telexaindia@gmail.com</a> 
          </p>
          <p><a href="tel:8989954333"> <i class="fa fa-phone" aria-hidden="true"></i>  +91 8989954333 </a> </p>
          <p><a href="tel:8989054333"><i class="fa fa-print" aria-hidden="true"></i> + 91 8989054333 </a> </p>
        </div>
      
      </div>
    
    </div>
  </section>
 
  <div class="text-center p-4" 
  // style="background-color: rgba(0, 0, 0, 0.05);"
  >
      © 2021 Copyright:
    <a class="text-reset fw-bold" href="https://telexa.in" target="blank">Telexa Technology</a>
  </div>
 
</footer>

//   <footer class="bg-dark text-center text-white" id="footer">

//   <div class="container p-4 pb-0">
   
//     <section class="mb-4">
      
//       <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button">
//       <i class="fa fa-facebook fa-2x" aria-hidden="true"></i>
//         </a>

    
//       <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button">
//       <i class="fa fa-twitter fa-2x" aria-hidden="true"></i>
//         </a>

      
//       <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button">
//       <i class="fa fa-google fa-2x" aria-hidden="true"></i>
//         </a>

     
//       <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button">
//       <i class="fa fa-instagram fa-2x" aria-hidden="true"></i>
//         </a>

    
      

  
//       <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button">
//       <i class="fa fa-linkedin-square fa-2x " aria-hidden="true"></i>
//         </a>
//     </section>
   
//   </div>
  


//   <div class="text-center p-3">
//     © 2020 Copyright: Telexa
//   </div>

// </footer>
 )
}