import { useState, useEffect } from "react";
import {Navigation}  from "./components/navigation";
import Header from "./components/header";
import { Features } from "./components/features";
import  {About} from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import { Router,Routes,Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { Footer } from "./components/footer";
import { Career } from "./components/career";
import { Image } from "./components/image";
import { Download } from "./components/download";
import { Support } from "./components/support";
import { SocialIcon } from "./components/scoailIcon";
export const scroll = new SmoothScroll('a[href*="/"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  },
   []);
   

return (
    
    <div>
      <Navigation/>
      {/* <SocialIcon/> */}
    <BrowserRouter>
      <Routes>
        <Route>
        
          <Route index element={<Header data={landingPageData.Header}/>} />
          <Route path="/features" element={<Features data={landingPageData.Features}/>} />
          <Route path="/about" element={<About data={landingPageData.About} />} />
          <Route path="/services" element={ <Services data={landingPageData.Services} />}/>
          <Route path="/gallery" element={ <Gallery data={landingPageData.Products}/>} />
        
          <Route path="/download" element={<Download data={landingPageData.Download}/>}/>
          <Route path="/support" element={<Support data={landingPageData.Support}/>}/>
          <Route path="/image" element={<Image data={landingPageData.Image}/>}/>       
          <Route path="/contact" element={<Contact data={landingPageData.Contact} />}/>
          <Route path="/career" element={<Career data={landingPageData.Career}/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
    <Footer/>
      {/* <Navigation />
      <Header data={landingPageData.Header}/>
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Gallery data={landingPageData.Products}/>
      <Team data={landingPageData.Team}/>
      <Contact data={landingPageData.Contact} /> */}
    </div>
    
  );
};

export default App;
