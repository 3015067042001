// export const Image=(props)=>{
//     return(
//       <>

//       {/* <section
//     //    {{style:"background-color: #eee;"}}
//        >
//   <div class="container py-5">
//     <h4 class="text-center mb-5"><strong>Product listing</strong></h4>

//     <div class="row">
//       <div class="col-lg-4 col-md-12 mb-4">
//         <div class="bg-image hover-zoom ripple shadow-1-strong rounded">
//           <img src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/E-commerce/new/img(1).webp"
//             class="w-100" />
//           <a href="#!">
//             <div class="mask"
//             //  style="background-color: rgba(0, 0, 0, 0.3);"
//              >
//               <div class="d-flex justify-content-start align-items-start h-100">
//                 <h5><span class="badge bg-light pt-2 ms-3 mt-3 text-dark">$123</span></h5>
//               </div>
//             </div>
//             <div class="hover-overlay">
//               <div class="mask" 
//             // {{ style: background-color= "rgba(253, 253, 253, 0.15);"}}
//               ></div>
//             </div>
//           </a>
//         </div>
//       </div>

//       <div class="col-lg-4 col-md-6 mb-4">
//         <div class="bg-image hover-zoom ripple shadow-1-strong rounded">
//           <img src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/E-commerce/new/img(2).webp"
//             class="w-100" />
//           <a href="#!">
//             <div class="mask"
//             //  style="background-color: rgba(0, 0, 0, 0.3);"
//              >
//               <div class="d-flex justify-content-start align-items-start h-100">
//                 <h5><span class="badge bg-light pt-2 ms-3 mt-3 text-dark">$239</span></h5>
//               </div>
//             </div>
//             <div class="hover-overlay">
//               <div class="mask"
//             //    style="background-color: rgba(253, 253, 253, 0.15);"
//                ></div>
//             </div>
//           </a>
//         </div>
//       </div>

//       <div class="col-lg-4 col-md-6 mb-4">
//         <div class="bg-image hover-zoom ripple shadow-1-strong rounded">
//           <img src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/E-commerce/new/img(3).webp"
//             class="w-100" />
//           <a href="#!">
//             <div class="mask"
//             //  style="background-color: rgba(0, 0, 0, 0.3);"
//              >
//               <div class="d-flex justify-content-start align-items-start h-100">
//                 <h5><span class="badge bg-light pt-2 ms-3 mt-3 text-dark">$147</span></h5>
//               </div>
//             </div>
//             <div class="hover-overlay">
//               <div class="mask" 
//             //   style="background-color: rgba(253, 253, 253, 0.15);"
//               ></div>
//             </div>
//           </a>
//         </div>
//       </div>
//     </div>

//     <div class="row">
//       <div class="col-lg-4 col-md-12 mb-4">
//         <div class="bg-image hover-zoom ripple shadow-1-strong rounded ripple-surface">
//           <img src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/E-commerce/new/img(4).webp"
//             class="w-100" />
//           <a href="#!">
//             <div class="mask"
//             //  style="background-color: rgba(0, 0, 0, 0.3);"
//              >
//               <div class="d-flex justify-content-start align-items-start h-100">
//                 <h5><span class="badge bg-light pt-2 ms-3 mt-3 text-dark">$83</span></h5>
//               </div>
//             </div>
//             <div class="hover-overlay">
//               <div class="mask"
//             //    style="background-color: rgba(253, 253, 253, 0.15);"
//                ></div>
//             </div>
//           </a>
//         </div>
//       </div>

//       <div class="col-lg-4 col-md-6 mb-4">
//         <div class="bg-image hover-zoom ripple shadow-1-strong rounded">
//           <img src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/E-commerce/new/img(5).webp"
//             class="w-100" />
//           <a href="#!">
//             <div class="mask" 
//             // style="background-color: rgba(0, 0, 0, 0.3);"
//             >
//               <div class="d-flex justify-content-start align-items-start h-100">
//                 <h5><span class="badge bg-light pt-2 ms-3 mt-3 text-dark">$106</span></h5>
//               </div>
//             </div>
//             <div class="hover-overlay">
//               <div class="mask" 
//             //   style="background-color: rgba(253, 253, 253, 0.15);"
//               ></div>
//             </div>
//           </a>
//         </div>
//       </div>

//       <div class="col-lg-4 col-md-6 mb-4">
//         <div class="bg-image hover-zoom ripple shadow-1-strong rounded">
//           <img src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/E-commerce/new/img(6).webp"
//             class="w-100" />
//           <a href="#!">
//             <div class="mask"
//             //  style="background-color: rgba(0, 0, 0, 0.3);"
//              >
//               <div class="d-flex justify-content-start align-items-start h-100">
//                 <h5><span class="badge bg-light pt-2 ms-3 mt-3 text-dark">$58</span></h5>
//               </div>
//             </div>
//             <div class="hover-overlay">
//               <div class="mask" 
//             //   style="background-color: rgba(253, 253, 253, 0.15);"
//               ></div>
//             </div>
//           </a>
//         </div>
//       </div>
//     </div>
//   </div>
// </section> */}

//     <div class="row">
//       <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
//         <div
//           class="bg-image hover-overlay ripple shadow-1-strong rounded"
//           data-ripple-color="light"
//         >
//            {props.data
//                       ? props.data.Why2.map((d, i) => (
//                           <li key={`${d}-${i}`}> {d}</li>
//                         ))
//                       : "loading"}
//           {/* <img
//             src="https://mdbcdn.b-cdn.net/img/screens/yt/screen-video-1.webp"
//             class="w-100"
//           /> */}
//           <a href="#!" data-mdb-toggle="modal" data-mdb-target="#exampleModal1">
//             <div class="mask"
//             //  style="background-color: rgba(251, 251, 251, 0.2);"
//              ></div>
//           </a>
//         </div>
//       </div>


  
//     </div>
     
      
//       </>
        
//     );
// }



export const Image = (props) => {
  return (
    <div id='team' className='text-center'>
      <div className='container'>
        {/* <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Meet the Team</h2>
        </div> */}
        <div id='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.id}-${i}`} className='col-md-3 col-sm-6 team'>
                  <div className='thumbnail'>
                    {' '}
                    <img src={d.img} alt='...' className='team-img' />
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
